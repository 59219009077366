


























































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import PageTitle from "@/components/General/PageTitle.vue";
import { Category as CategoryInterface } from "@/models/category.interface";
import Component, { mixins } from "vue-class-component";
import { Notification } from "@/models/notification.interface";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import Category from "@/components/Layout/Common/Category.vue";

@Component({
  components: { PageTitle, Loader, Empty, Category },
})
export default class CategoriesForClient extends mixins(
  FormValidations,
  Navigation
) {
  loader = false;
  loading = false;

  private get categories(): CategoryInterface[] {
    let response = this.$store.getters["categories/getCategories"];
    if (response?.categories) {
      const activeCategories = response.categories.filter(
        (category: CategoryInterface) => {
          return category.status?.name == this.$constants.STATUS.ACTIVE;
        }
      );
      return activeCategories;
    } else {
      return [];
    }
  }

  private async created() {
    window.scrollTo(0, 0);
    await this.getCategories();
  }

  private async getCategories() {
    this.loader = true;
    await this.$store
      .dispatch("categories/getCategories")
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Categories.fetchError.get"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loader = false;
      });
  }

  private goToCategory(category: CategoryInterface) {
    this.navigate("/catalogue/category/" + category.id + "/" + 1);
  }

  private get categoriesWithChilds() {
    return this.categories.filter((category: CategoryInterface) =>
      category.categories !== undefined ? category.categories.length > 0 : false
    );
  }

  private get categoriesWithoutChilds() {
    return this.categories.filter((category: CategoryInterface) =>
      category.categories !== undefined
        ? category.categories.length == 0
        : false
    );
  }
}
